/* eslint-disable no-template-curly-in-string */

import { setLocale } from "yup";

const ptBR = {
  mixed: {
    required: "${path} é requerido",
    notType: "${path} é inválido",
  },
  string: {
    max: "${path} precisa ter no máximo ${max} caracteres",
    email: "${path} inválido",
  },
  number: {
    min: "${path} precisa ser no mínimo ${min}",
  },
};

setLocale(ptBR);

export * from "yup";
