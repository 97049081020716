import React, { useEffect, useRef, useState } from "react";
import produtosRepositories, {
  SateliteDadosType,
  SateliteType,
} from "../../repositories/Produtos";
import { IResponse } from "../../repositories/models";
import { ImageOverlay } from "react-leaflet";

import ButtonLegend from "../../components/ButtonLegend";
import TemporaryDrawer from "../../components/TemporaryDrawer";
import GradientLegend from "../../components/GradientLegend";
import SatelliteIcon from "@mui/icons-material/Satellite";
import useLayerChecked from "../../hooks/useLayerChecked";

const paramsDefault = "realcada";
const layer = "Satélite";
const Satelite = () => {
  const subscribed = useRef(true);
  const [latLon, setLatLon] = useState<SateliteType["lat_lon"]>({
    id: 1,
    lat_max: 12.52,
    lat_min: -56,
    lon_max: -25.24,
    lon_min: -100,
  });
  const [satelite, setSatelite] = useState<SateliteDadosType>({
    data: "",
    path: "",
    tamanho: 0,
  });
  const [hora, setHora] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const { checkedLayer } = useLayerChecked({
    layer: layer,
  });
  useEffect(() => {
    subscribed.current = true;
    getSatelite();
    return () => {
      subscribed.current = false;
    };
  }, []);

  const getSatelite = async (parameters = paramsDefault) => {
    try {
      const { data } = await produtosRepositories.satelite<
        IResponse<SateliteType>
      >(parameters);
      if (subscribed.current) {
        if (data.data.satelite !== undefined) {
          setLatLon(data.data.lat_lon);
          setSatelite(data.data.satelite[0]);
          setHora(data.data.anima[0] + "Z");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(open);
    };
  if (!checkedLayer) {
    return null;
  }
  return (
    <>
      {satelite.path && (
        <>
          <ImageOverlay
            bounds={[
              [latLon.lat_min, latLon.lon_min],
              [latLon.lat_max, latLon.lon_max],
            ]}
            url={satelite.path}
          />
        </>
      )}
      <ButtonLegend
        onClick={toggleDrawer(true)}
        position={"topright"}
        info={hora}
      >
        <SatelliteIcon />
      </ButtonLegend>
      <TemporaryDrawer
        open={open}
        onClose={toggleDrawer(false)}
        anchor={"bottom"}
      >
        <GradientLegend
          title="Realçada"
          endTitle="Temperatura(ºC)"
          valuesList={[
            { label: "45", color: ["#0D0D0D", "#D7D7D7"], scale: 2 },
            { label: "-30", color: ["#D7D7D7", "#FF9900"], scale: 1 },
            { label: "-40", color: ["#FF9900", "#FEFE00"], scale: 1 },
            { label: "-50", color: ["#FEFE00", "#00CCFE"], scale: 1 },
            { label: "-60", color: ["#00CCFE", "#00CCFE"], scale: 1 },
            { label: "-70", color: ["#0000FE", "#0000FE"], scale: 1 },
            { label: "-80", color: ["#FF99CC", "#FF99CC"], scale: 1 },
            { label: "-90", color: ["#FF0405"], scale: 1 },
          ]}
        />
      </TemporaryDrawer>
    </>
  );
};

export default Satelite;
