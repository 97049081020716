import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from "axios";
import Api from "../config/Api";

export class Aerodromos {
  private cancelList: CancelTokenSource | null = null;

  constructor(private http: AxiosInstance, protected resource: string) {}

  status<T = any>(paises: any): Promise<AxiosResponse<T>> {
    return this.http.get<T>(`${this.resource}/status?pais=${paises}`);
  }

  pesquisa<T = any>(pesquisa: string): Promise<AxiosResponse<T>> {
    if (this.cancelList) {
      this.cancelList.cancel("list cancelled");
    }
    this.cancelList = axios.CancelToken.source();
    const config: AxiosRequestConfig = {
      cancelToken: this.cancelList.token,
    };

    return this.http.get<T>(`${this.resource}/?pesquisa=${pesquisa}`, config);
  }
}

const aerodromosRepositories = new Aerodromos(Api, "aerodromos");
export default aerodromosRepositories;
