import React, { useEffect, useRef, useState } from "react";
import produtosRepositories from "../../repositories/Produtos";
import { Box } from "@mui/material";
import Loading from "../../components/Loading";

const Sigwx = () => {
  const subscribed = useRef(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>("");

  useEffect(() => {
    subscribed.current = true;
    getSigwx();
    return () => {
      subscribed.current = false;
    };
  }, []);

  const getSigwx = async () => {
    try {
      setLoading(true);
      const { data } = await produtosRepositories.sigwx();
      setImageUrl(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box sx={{ padding: "10px" }}>
      {loading ? (
        <Loading />
      ) : (
        <Box style={{ textAlign: "center" }}>
          <img src={imageUrl} width={350} alt={"Sigwx"} />
        </Box>
      )}
    </Box>
  );
};

export default Sigwx;
