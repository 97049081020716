import { AxiosInstance, AxiosResponse } from "axios";
import Api from "../config/Api";

export interface SateliteDadosType {
  data: string;
  path: string;
  tamanho: number;
}

export interface SateliteType {
  tipo: string;
  lat_lon: {
    id: number;
    lon_min: number;
    lon_max: number;
    lat_min: number;
    lat_max: number;
  };
  anima: string[];
  satelite: SateliteDadosType[];
}

export interface StscType {
  anima: string[];
  stsc: StscDadoType[];
}

export interface StscDadoType {
  ti: string;
  la: number;
  lo: number;
  to: number;
  ra: string;
}

export interface RadarType {
  tipo: string;
  anima: string[];
  radar: RadarDadosType[];
}

export interface RadarDadosType {
  id: number;
  nome: string;
  localidade: string;
  raio: number;
  lat_center: number;
  lon_center: number;
  lon_min: number;
  lon_max: number;
  lat_min: number;
  lat_max: number;
  path: string;
  tamanho: number;
  data: string;
}

export const StscInitial = {
  anima: [],
  stsc: [],
};

export class Produtos {
  constructor(private http: AxiosInstance, protected resource: string) {}

  satelite<T = any>(tipo: string): Promise<AxiosResponse<T>> {
    return this.http.get<T>(`${this.resource}/satelite/${tipo}`);
  }

  stsc<T = any>(): Promise<AxiosResponse<T>> {
    return this.http.get<T>(`${this.resource}/stsc`);
  }

  radar<T = any>(tipo: string): Promise<AxiosResponse<T>> {
    return this.http.get<T>(`${this.resource}/radar/${tipo}`);
  }

  sigwx<T = any>(): Promise<AxiosResponse<T>> {
    return this.http.get<T>(`${this.resource}/sigwx`);
  }
}

const produtosRepositories = new Produtos(Api, "produtos");
export default produtosRepositories;
