import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import InfoIcon from "@mui/icons-material/Help";
import { FC, ReactNode } from "react";
import MenuList from "./MenuList";
import Map from "../Leaflet/Map";
import ControlDiv from "../Leaflet/ControlDiv";
import ButtonLegend from "../ButtonLegend";

const mdTheme = createTheme();
interface LayoutProps {
  title?: string;
  children?: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <ThemeProvider theme={mdTheme}>
      <Map>
        <ButtonLegend
          onClick={() =>
            window.open(
              "https://ajuda.decea.mil.br/artigo-categoria/redemet-lite/",
              "_blank"
            )
          }
          position={"topright"}
        >
          <InfoIcon style={{ marginTop: "3px" }} />
        </ButtonLegend>
        <ControlDiv position={"topleft"}>
          <MenuList />
        </ControlDiv>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
        </Box>
      </Map>
    </ThemeProvider>
  );
};

export default Layout;
