import React, { FC } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import InstallDialogAction from "./InstallDialogAction";

interface InstallDialogType {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  logo?: any;
  title: string;
  subtitle?: string;
  features?: any;
  platform: string;
  description?: string;
}

const InstallDialog: FC<InstallDialogType> = ({
  open,
  onClose,
  onSubmit,
  title,
  subtitle,
  logo,
  features,
  platform,
  description,
}) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="dialog-title">
      <DialogTitle id="dialog-title">{title || "Install Web App"}</DialogTitle>
      <DialogContent dividers={true}>
        <Box display="flex" alignItems="flex-start">
          {!!logo && (
            <Box mr={1}>
              <img src={logo} alt="logo" width={60} />
            </Box>
          )}
          {!!subtitle && (
            <Box>
              <Typography variant="subtitle1">{subtitle}</Typography>
            </Box>
          )}
        </Box>
        {!!description && (
          <>
            <Typography variant="body2" component="div">
              {description}
            </Typography>
          </>
        )}
      </DialogContent>
      <InstallDialogAction
        onClose={onClose}
        platform={platform}
        onSubmit={onSubmit}
      />
    </Dialog>
  );
};

export default InstallDialog;
