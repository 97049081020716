import { AxiosInstance, AxiosResponse } from "axios";
import Api from "../config/Api";
import { LatLngExpression } from "leaflet";

export interface MensagemType {
  metar: string;
  taf: string;
}

export interface SigmetType {
  id_fir: string;
  validade_inicial: string;
  validade_final: string;
  title: string;
  mens: string;
  no_mens: string;
  fenomeno: string;
  fenomeno_comp: string;
  fenomeno_cor: string;
  fenomeno_transparencia: string;
  lat_lon: LatLngExpression[];
}

export class Mensagens {
  constructor(private http: AxiosInstance, protected resource: string) {}

  metar<T = any>(cod: string): Promise<AxiosResponse<T>> {
    return this.http.get<T>(`${this.resource}/pwa/${cod}`);
  }

  taf<T = any>(cod: string): Promise<AxiosResponse<T>> {
    return this.http.get<T>(`${this.resource}/taf/${cod}`);
  }

  sigmet<T = any>(): Promise<AxiosResponse<T>> {
    return this.http.get<T>(`${this.resource}/sigmet`);
  }
}

const mensagensRepositories = new Mensagens(Api, "mensagens");
export default mensagensRepositories;
