import React from "react";
import { FormControlProps, FormHelperText } from "@mui/material";
import aerodromosRepositories from "../../../repositories/Aerodromos";
import AsyncAutocomplete from "../../../components/AsyncAutocomplete";

interface LocalidadesFieldProps {
  setLocalidades: (localidade: any) => void;
  error?: any;
  FormControlProps?: FormControlProps;
}
const LocalidadesField = (props: LocalidadesFieldProps) => {
  const { setLocalidades, error } = props;

  function fetchOptions(pesquisa: string) {
    return aerodromosRepositories
      .pesquisa(pesquisa)
      .then((data: any) => data.data.data);
  }

  return (
    <>
      <AsyncAutocomplete
        AutocompleteProps={{
          multiple: true,
          getOptionLabel: (option: any) => option.cod + " - " + option.nome,
          onChange: (event: any, value: any) => {
            setLocalidades(value);
          },
          disableCloseOnSelect: false,
          freeSolo: true,
          id: "localidades-id",
        }}
        fetchOptions={fetchOptions}
        TextFieldProps={{
          label: "Localidades",
          id: "origem-id",
          margin: "normal",
        }}
      />
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </>
  );
};

export default LocalidadesField;
