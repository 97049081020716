import React, { FC } from "react";

import {
  AppBar,
  Dialog,
  DialogContent,
  IconButton,
  ListItem,
  SvgIconProps,
  Toolbar,
  Typography,
} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";

interface ModalType {
  title: string;
  menu: string;
  linkExterno?: boolean;
  icon: React.ReactElement<SvgIconProps>;
  children: React.ReactNode;
}

const MenuModal: FC<ModalType> = ({ title, menu, icon, children }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ListItem
        disableGutters={true}
        sx={{ paddingLeft: "6px" }}
        button
        onClick={handleClickOpen}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={menu} />
      </ListItem>

      <Dialog
        fullScreen
        open={open}
        aria-labelledby="max-width-dialog-title"
        scroll="body"
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent>{children}</DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default MenuModal;
