import React, { useState } from "react";
import LocalidadesField from "./components/localidadesField";
import * as yup from "../../vendor/yup";
import { Box, Button, CircularProgress } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import mensagensRepositories from "../../repositories/Mensagens";
import ListMensage from "./components/ListMensage";

interface MensagensProps {
  localidades: any[];
}

const schema = yup.object().shape({
  localidades: yup
    .array()
    .min(1, "Localidade é requerido")
    .label("Localidade")
    .required(),
});

const Mensagens = () => {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      localidades: [],
    },
  });
  const [mensagens, setMensagens] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState(false);

  const getMetarTaf = async (cod: string) => {
    try {
      setLoading(true);
      const { data } = await mensagensRepositories.metar<any>(cod);
      setMensagens(data);
      setSearch(true);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<MensagensProps> = (formData) => {
    const localidades = formData.localidades.map((row) => row.cod);
    getMetarTaf(localidades.join());
  };
  return (
    <Box sx={{ padding: "10px" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LocalidadesField
          setLocalidades={(value) => setValue("localidades", value)}
          error={errors.localidades}
        />
        <Button variant="contained" type="submit" color="primary">
          Consultar
        </Button>
      </form>
      {loading && <CircularProgress />}
      {search && (
        <>
          <ListMensage mens={mensagens} />
        </>
      )}
    </Box>
  );
};

export default Mensagens;
