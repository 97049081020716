import React from "react";

import { List } from "@mui/material";
import MenuLayer from "../Leaflet/MenuLayer";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import { LayerGroup } from "react-leaflet";
import Aerodromos from "../../produtos/Aerodromos";
import Sigmet from "../../produtos/Sigmet";
import WaterIcon from "@mui/icons-material/Water";
import SettingsInputAntennaIcon from "@mui/icons-material/SettingsInputAntenna";
import Radar from "../../produtos/Radar";
import SatelliteIcon from "@mui/icons-material/Satellite";
import Satelite from "../../produtos/Satelite";
import { CloudLightning } from "react-feather";
import Tsc from "../../produtos/Tsc";
import MenuModal from "../MenuModal";
import { FilterDrama, ManageSearch } from "@mui/icons-material";
import Mensagens from "../../produtos/Mensagens";
import Sigwx from "../../produtos/Sigwx";
import MenuInstall from "../MenuInstall";

const ListItems = () => {
  return (
    <List>
      <MenuLayer name={"Aerodromos"} checked icon={<AirplanemodeActiveIcon />}>
        <LayerGroup>
          <Aerodromos />
        </LayerGroup>
      </MenuLayer>
      <MenuModal title={"Mensagens"} menu={"Mensagens"} icon={<ManageSearch />}>
        <Mensagens />
      </MenuModal>
      <MenuLayer name={"Sigmet"} icon={<WaterIcon />}>
        <LayerGroup>
          <Sigmet />
        </LayerGroup>
      </MenuLayer>
      <MenuLayer name={"Radar"} icon={<SettingsInputAntennaIcon />}>
        <LayerGroup>
          <Radar />
        </LayerGroup>
      </MenuLayer>
      <MenuLayer name={"Satélite"} icon={<SatelliteIcon />}>
        <LayerGroup>
          <Satelite />
        </LayerGroup>
      </MenuLayer>
      <MenuLayer name={"Tsc"} icon={<CloudLightning />}>
        <LayerGroup>
          <Tsc />
        </LayerGroup>
      </MenuLayer>
      <MenuModal title={"SIGWX"} menu={"Sigwx"} icon={<FilterDrama />}>
        <Sigwx />
      </MenuModal>
      <MenuInstall />
    </List>
  );
};
export default ListItems;
