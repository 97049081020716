import * as React from "react";
import { Polygon, Popup } from "react-leaflet";
import { useCallback, useEffect, useRef, useState } from "react";
import mensagensRepositories, {
  SigmetType,
} from "../../repositories/Mensagens";
import { IResponse, RequestPaginate } from "../../repositories/models";
import { Typography } from "@mui/material";

import ButtonLegend from "../../components/ButtonLegend";
import TemporaryDrawer from "../../components/TemporaryDrawer";
import LineLegend from "../../components/LineLegend";
import WaterIcon from "@mui/icons-material/Water";
import useLayerChecked from "../../hooks/useLayerChecked";

const columnsLegend = ["Fenômeno", "Descrição", "Cor"];
const dataLegend = [
  ["VA CLD", "Cinzas Vulcânicas", "FF7F50"],
  ["RDOACT CLD", "Nuvem Radioativa", "CEECF5"],
  ["ICE", "Gelo", "00BFFF"],
  ["TC", "Ciclone", "009900"],
  ["TS", "Trovoada", "FF0000"],
  ["TURB", "Turbulência", "FFFF00"],
  ["VA", "Vulcão", "5C2E00"],
];
const layer = "Sigmet";
const Sigmet = () => {
  const subscribed = useRef(true);
  const [data, setData] = useState<SigmetType[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const { checkedLayer } = useLayerChecked({
    layer: layer,
  });
  const getSigmet = useCallback(async () => {
    setData([]);
    try {
      const response = await mensagensRepositories.sigmet<
        IResponse<RequestPaginate<SigmetType>>
      >();
      if (subscribed.current) {
        setData(response.data.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    subscribed.current = true;
    getSigmet();
    return () => {
      subscribed.current = false;
    };
  }, [getSigmet]);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(open);
    };

  if (!checkedLayer) {
    return null;
  }

  return (
    <>
      {data.map((obj, index) => (
        <Polygon key={index} color={obj.fenomeno_cor} positions={obj.lat_lon}>
          <Popup>
            <Typography variant="button" display="block" gutterBottom>
              {obj.title}k
            </Typography>
            {obj.fenomeno_comp}
          </Popup>
        </Polygon>
      ))}
      <ButtonLegend onClick={toggleDrawer(true)} position={"topright"}>
        <WaterIcon />
      </ButtonLegend>

      <TemporaryDrawer
        open={open}
        onClose={toggleDrawer(false)}
        anchor={"bottom"}
      >
        <LineLegend columns={columnsLegend} data={dataLegend} />
      </TemporaryDrawer>
    </>
  );
};

export default Sigmet;
