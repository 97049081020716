import React from "react";
import { Box, Button, DialogActions, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { platforms } from "./Platforms";
import {
  IOSShareIcon,
  FireFoxA2HSIcon,
  MenuIcon,
  OperaA2HSIcon,
} from "./icons";

interface DialogActionWithInstructionsType {
  action1: ReactNode;
  action2: ReactNode;
  onSubmit: () => void;
}

const DialogActionWithInstructions: FC<DialogActionWithInstructionsType> = ({
  action1,
  action2,
  onSubmit,
}) => {
  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box>
        <Typography variant="subtitle1">
          Para instalar este aplicativo:
        </Typography>
        <ul>
          <li>
            <span style={{ display: "flex", alignItems: "center" }}>
              {action1}
            </span>
          </li>
          <li>{action2}</li>
        </ul>
      </Box>
      <Box width="100%" textAlign="right">
        <Button onClick={onSubmit}>Ok</Button>
      </Box>
    </Box>
  );
};

interface InstallDialogActionType {
  onClose: () => void;
  platform: string;
  onSubmit: () => void;
}

const InstallDialogAction: FC<InstallDialogActionType> = ({
  onClose,
  platform,
  onSubmit,
}) => {
  return (
    <>
      <DialogActions>
        {platform === platforms.NATIVE && (
          <>
            <Button onClick={onClose}>Cancelar</Button>
            <Button
              onClick={onSubmit}
              color="primary"
              variant="contained"
              disableElevation
            >
              Instalar
            </Button>
          </>
        )}
        {platform === platforms.IDEVICE && (
          <DialogActionWithInstructions
            action1={
              <>
                Toque no botão de compartilhamento:
                <IOSShareIcon />
              </>
            }
            action2="em seguida, localize e toque 'Adicionar à Tela de Início'"
            onSubmit={onSubmit}
          />
        )}
        {platform === platforms.FIREFOX && (
          <DialogActionWithInstructions
            action1={
              <>
                Toque neste ícone na barra de endereço:
                <FireFoxA2HSIcon />
              </>
            }
            action2="em seguida, toque '+Adicionar à Tela de Início'"
            onSubmit={onSubmit}
          />
        )}
        {platform === platforms.FIREFOX_NEW && (
          <DialogActionWithInstructions
            action1={
              <>
                Toque no botão de menu:
                <MenuIcon />
              </>
            }
            action2="em seguida, toque 'Install'"
            onSubmit={onSubmit}
          />
        )}
        {platform === platforms.OPERA && (
          <DialogActionWithInstructions
            action1={
              <>
                Toque no botão de menu:
                <MenuIcon />
              </>
            }
            action2={
              <>
                em seguida, toque
                <OperaA2HSIcon />
                Home screen
              </>
            }
            onSubmit={onSubmit}
          />
        )}
        {platform === platforms.OTHER && (
          <Box width="100%" display="flex" flexDirection="column">
            <Box>
              Infelizmente, o recurso de instalação não é suportado pelo seu
              navegador.
            </Box>
            <Box width="100%" textAlign="right">
              <Button onClick={onClose}>Ok</Button>
            </Box>
          </Box>
        )}
      </DialogActions>
    </>
  );
};

export default InstallDialogAction;
