import * as React from "react";
import { DrawerProps } from "@mui/material/Drawer";
import { Drawer } from "@mui/material";

interface TemporaryDrawerProps extends DrawerProps {
  children: React.ReactNode;
}

export default function TemporaryDrawer({
  children,
  ...other
}: TemporaryDrawerProps) {
  return (
    <div>
      <Drawer {...other}>{children}</Drawer>
    </div>
  );
}
