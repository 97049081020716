import React from "react";

import { ListItem } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DownloadIcon from "@mui/icons-material/Download";
import { useReactPWAInstall } from "../vendor/pwa-install";

const MenuInstall = () => {
  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

  const handleClick = () => {
    pwaInstall({
      title: "Instalar Web App",
      subtitle: "REDEMET LITE",
      logo: "logo192.png",
      features: (
        <ul>
          <li>Cool feature 1</li>
          <li>Cool feature 2</li>
          <li>Even cooler feature</li>
          <li>Works offline</li>
        </ul>
      ),
      description: "Desenvolvido para dispositivos móveis",
    });
  };

  if (supported() && !isInstalled()) {
    return (
      <ListItem
        disableGutters={true}
        sx={{ paddingLeft: "6px" }}
        button
        onClick={handleClick}
      >
        <ListItemIcon>{<DownloadIcon />}</ListItemIcon>
        <ListItemText primary={"Instalar"} />
      </ListItem>
    );
  } else {
    return null;
  }
};

export default MenuInstall;
