import React, { useEffect, useRef, useState } from "react";
import produtosRepositories, {
  StscDadoType,
  StscType,
} from "../../repositories/Produtos";
import { IResponse } from "../../repositories/models";
import { Circle } from "react-leaflet";
import ButtonLegend from "../../components/ButtonLegend";
import useLayerChecked from "../../hooks/useLayerChecked";
import { CloudLightning } from "react-feather";

const layer = "Tsc";

const Tsc = () => {
  const subscribed = useRef(true);
  const [data, setData] = useState<StscDadoType[]>([]);
  const [hora, setHora] = useState<string>("");
  const { checkedLayer } = useLayerChecked({
    layer: layer,
  });

  useEffect(() => {
    subscribed.current = true;
    getTsc();
    return () => {
      subscribed.current = false;
    };
  }, []);

  const getTsc = async () => {
    try {
      const { data } = await produtosRepositories.stsc<IResponse<StscType>>();
      if (subscribed.current) {
        if (data.data.stsc !== undefined) {
          setData(data.data.stsc[0] as any);
          setHora(data.data.anima[0]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (!checkedLayer) {
    return null;
  }

  return (
    <>
      {data.map((row, index) => (
        <Circle
          key={index}
          center={[row.la, row.lo]}
          pathOptions={{ color: "red", fillOpacity: 1 }}
          radius={10000}
        />
      ))}
      <ButtonLegend position={"topright"} info={`${hora}Z`}>
        <CloudLightning />
      </ButtonLegend>
    </>
  );
};

export default Tsc;
