import * as React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import { Box, ListItem } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import { MensagemType } from "../../../repositories/Mensagens";
import nl2br from "react-nl2br";

interface ListMensageProps {
  mens: MensagemType[];
}

const ListMensage = ({ mens }: ListMensageProps) => {
  return (
    <>
      {Object.keys(mens).length > 0 ? (
        Object.keys(mens).map((row, index) => (
          <List
            key={index}
            sx={{
              width: "100%",
              marginTop: "12px",
              bgcolor: "background.paper",
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                {row}
              </ListSubheader>
            }
          >
            <ListItem>
              <ListItemText primary={nl2br(mens[row as any].metar)} />
            </ListItem>
            <ListItem>
              <ListItemText primary={nl2br(mens[row as any].taf)} />
            </ListItem>
          </List>
        ))
      ) : (
        <Box style={{ marginTop: "25px" }}>Nenhuma informação disponível.</Box>
      )}
    </>
  );
};

export default ListMensage;
// import React from "react";
// import { makeStyles, Theme, createStyles } from "@mui/material/styles";
// import ListSubheader from "@mui/material/ListSubheader";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
//
// import { MensagemType } from "../../../repositories/Mensagens";
// import nl2br from "react-nl2br";
//
// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       width: "100%",
//       backgroundColor: theme.palette.background.paper,
//     },
//     nested: {
//       paddingLeft: theme.spacing(4),
//     },
//     title: {
//       backgroundColor: "#e0e0e0",
//       fontWeight: "bold",
//       color: "#000",
//     },
//   })
// );
//
// interface ListMensageProps {
//   mens: MensagemType[];
// }
//
// const ListMensage = ({ mens }: ListMensageProps) => {
//   const classes = useStyles();
//
//   return (
//     <>
//       {Object.keys(mens).length > 0
//         ? Object.keys(mens).map((row, index) => (
//             <List
//               key={index}
//               component="nav"
//               subheader={
//                 <ListSubheader
//                   className={classes.title}
//                   component="div"
//                   id="nested-list-subheader"
//                 >
//                   {row}
//                 </ListSubheader>
//               }
//               className={classes.root}
//             >
//               <ListItem>
//                 <ListItemText primary={nl2br(mens[row as any].metar)} />
//               </ListItem>
//               <ListItem>
//                 <ListItemText primary={nl2br(mens[row as any].taf)} />
//               </ListItem>
//             </List>
//           ))
//         : "Nenhuma informação disponível."}
//     </>
//   );
// };
//
// export default ListMensage;
