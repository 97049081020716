import React, { ReactNode } from "react";
import { MapContainer, WMSTileLayer, ZoomControl } from "react-leaflet";
import { CENTER, MINZOOM, ZOOM } from "../../config/map";
import { CRS } from "leaflet";
import ControlDiv from "./ControlDiv";
import Loading from "../Loading";

interface MapProps {
  children?: ReactNode;
  loading?: boolean;
}
const Map = ({ children, loading }: MapProps) => {
  return (
    <MapContainer
      center={CENTER}
      zoomControl={false}
      zoom={ZOOM}
      minZoom={MINZOOM}
      attributionControl={true}
      crs={CRS.EPSG4326}
      scrollWheelZoom={false}
      tap={false}
    >
      <ZoomControl position="topright" />
      <WMSTileLayer
        layers="OSM-WMS"
        url="https://ows.terrestris.de/osm/service?"
      />

      {children && children}
      {loading && (
        <ControlDiv position={"bottomright"}>
          <Loading />
        </ControlDiv>
      )}
    </MapContainer>
  );
};

export default Map;
