import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = TableCell;

// const StyledTableCell = withStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       verticalAlign: "middle",
//     },
//     head: {
//       backgroundColor: theme.palette.common.black,
//       color: theme.palette.common.white,
//       fontSize: 12,
//     },
//     sizeSmall: {
//       padding: "0px 5px 0px 5px",
//     },
//     body: {
//       fontSize: 11,
//     },
//   })
// )(TableCell);

export default function LegendStatus() {
  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <StyledTableCell colSpan={2}>
                Quanto à hora da informação do METAR
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell>Informação da hora atual</StyledTableCell>
              <StyledTableCell>
                <img
                  src={"/assets/images/aerodromos/currentcolor.svg"}
                  width={50}
                  alt={"Current Color"}
                />
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                Informações de até 3 horas passadas
              </StyledTableCell>
              <StyledTableCell>
                <img
                  src={"/assets/images/aerodromos/pastcolor.svg"}
                  width={50}
                  alt={"Past Color"}
                />
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell>
                Sem informações de até 3 horas passadas
              </StyledTableCell>
              <StyledTableCell>
                <img
                  src={"/assets/images/aerodromos/cinza.svg"}
                  width={15}
                  alt={"Cinza"}
                />
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
