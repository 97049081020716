import React, { FC, useEffect, useState } from "react";
import { TextFieldProps } from "@mui/material/TextField";

import { useDebounce } from "use-debounce";
import {
  Autocomplete,
  AutocompleteProps,
  CircularProgress,
  TextField,
} from "@mui/material";

interface AsyncAutocompleteProps {
  fetchOptions: (search: string) => Promise<any>;
  debounceTime?: number;
  TextFieldProps?: TextFieldProps;
  AutocompleteProps?: Omit<
    AutocompleteProps<any, any, any, any>,
    "renderInput" | "options"
  >;
}

const AsyncAutocomplete: FC<AsyncAutocompleteProps> = (
  props: AsyncAutocompleteProps
) => {
  const { AutocompleteProps, debounceTime = 300, fetchOptions } = props;
  const { freeSolo, onOpen, onClose } = AutocompleteProps as any;
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [options, setOptions] = useState([]);
  const [debouncedSearchText] = useDebounce(searchText, debounceTime);
  const loading = open && options.length === 0;

  useEffect(() => {
    if (!open) {
      return;
    }
    if (debouncedSearchText === "" && freeSolo) {
      return;
    }

    let isSubscribed = true;
    (async () => {
      try {
        const data = await fetchOptions(debouncedSearchText);
        if (isSubscribed) {
          setOptions(data);
        }
      } catch (e) {
        console.log(e);
      }
    })();
    return () => {
      isSubscribed = false;
    };
  }, [debouncedSearchText, fetchOptions, freeSolo, open]);

  const textFieldProps: TextFieldProps = {
    margin: "normal",
    variant: "outlined",
    fullWidth: true,
    InputLabelProps: { shrink: true },
    ...(props.TextFieldProps && { ...props.TextFieldProps }),
  };

  const autocompleteProps: AutocompleteProps<any, any, any, any> = {
    loadingText: "Carregando...",
    noOptionsText: "Nenhum item encontrado",
    ...(AutocompleteProps && { ...AutocompleteProps }),
    open,
    options,
    loading,
    inputValue: searchText,
    onOpen() {
      setOpen(true);
      onOpen && onOpen();
    },
    onClose() {
      setOpen(false);
      onClose && onClose();
    },
    onInputChange(event, value) {
      setSearchText(value);
    },
    // eslint-disable-next-line react/display-name
    renderInput: (params) => (
      <TextField
        {...params}
        {...textFieldProps}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {loading && <CircularProgress color="inherit" size={20} />}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    ),
  };

  return <Autocomplete {...autocompleteProps} />;
};

export default AsyncAutocomplete;
