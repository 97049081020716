import React from "react";
import styled from "styled-components";
import { Box } from "@mui/system";
import ControlDiv from "./Leaflet/ControlDiv";
import { ControlPosition } from "leaflet";

const Button = styled.a`
  font-size: 18px;
  font-weight: bold;
  padding: 2px;
`;

const Info = styled.span`
  font-weight: bold;
  font-size: 10px;

  background-color: #fff;
`;

interface ButtonLegendProp {
  position?: ControlPosition;
  children: React.ReactNode;
  info?: string;
  onClick?: (value: any) => void;
}

const ButtonLegend = ({
  children,
  position = "topleft",
  onClick,
  info,
}: ButtonLegendProp) => {
  return (
    <ControlDiv position={position}>
      <Box
        className={"leaflet-bar"}
        style={{ backgroundColor: "#fff", textAlign: "center" }}
      >
        <Button onClick={onClick}>{children}</Button>
        {info && <Info>{info}</Info>}
      </Box>
    </ControlDiv>
  );
};

export default ButtonLegend;
